<template>
  <div>
    <ion-radio-group :value="1">
      <ion-item @click.prevent="updateStatus(1)"> 
        <div tabindex="0"></div><!-- bug fix for safari 14 -->
        <ion-radio slot="start" :value="1"></ion-radio> Profile is active 
      </ion-item> 
      <ion-item @click.prevent="updateStatus(2)">
        <div tabindex="0"></div><!-- bug fix for safari 14 --> 
        <ion-radio slot="start" :value="2"></ion-radio> Profile is not active 
      </ion-item> 
      <ion-item @click.prevent="updateStatus(3)"> 
        <div tabindex="0"></div><!-- bug fix for safari 14 -->
        <ion-radio slot="start" :value="3"></ion-radio> Schedule account for deletion 
      </ion-item> 
    </ion-radio-group>
  </div>
</template>

<script>
import { IonItem, IonRadio, IonRadioGroup, loadingController, toastController } from '@ionic/vue';
import { defineComponent, ref } from 'vue';
//import { person } from 'ionicons/icons';
import apiClient from '../services/api';

export default defineComponent({
  name: 'ProfileSecurity', 
  props: ['store'],
  components: {
    IonItem, IonRadio, IonRadioGroup
  },
  setup(props) {
    const timeout = { default: 1000 }
    const profileStatus = ref(1)

    async function presentLoading() {
      const loading = await loadingController
      .create({
        cssClass: 'my-custom-class',
        message: 'Please wait...',
        duration: timeout,
      });

      await loading.present();

      setTimeout(function() {
        loading.dismiss()
      }, timeout);
    }

    async function openToast() {
      const toast = await toastController
      .create({
        //message: '',
        duration: 2000,
        message: 'Your profile has been updated.',
        position: 'bottom',
        buttons: [
          {
            icon: 'close',
            role: 'cancel',
            handler: () => {

            }
          }
        ]
      })
      return toast.present();
    }

    function updateStatus(val) {
      profileStatus.value = val

      presentLoading()
      apiClient.post('/api/update/account/status', {
        status: profileStatus.value          
      },
      {
        headers: {
          'Authorization':  `Bearer ${props.store.authToken}`
        }
      }).then(response => {
        console.log(response)
        localStorage.setItem('authUser', JSON.stringify(response.data.user));
        this.dataUp(response.data.user);
        openToast()
      }).catch(error => {
        console.log(error)  
        // credentials didn't match
        //this.isError = true
        //this.errors = error.response.data.errors
      });

    }

    /*async function openToast() {
      const toast = await toastController
        .create({
          //message: '',
          duration: 2000,
          message: 'Your profile has been updated.',
          position: 'bottom',
          buttons: [
            {
              icon: 'close',
              role: 'cancel',
              handler: () => {
                
              }
            }
          ]
        })
      return toast.present();
    }*/


    return {
      presentLoading, profileStatus, updateStatus
    }
  },
  methods: {
    dataUp: function(user) {
      var componentData = {
        dataType: 'user',
        data: user
      }

      this.$emit('component-data', componentData)
    }
  }
});
</script>